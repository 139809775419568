import React from 'react';
import ReactDOM from 'react-dom';

export default function render(tag, eleId, props) {
  const Tag = tag;
  if (window.commonPage) {
    window.commonPage.render(function (config) {
      ReactDOM.render(<Tag {...config.props} />, document.getElementById(config.eleId));
    });
  } else ReactDOM.render(<Tag {...props} />, document.getElementById(eleId));
}
