import React from 'react';
import '@elastic/eui/dist/eui_theme_light.css';
import { EuiButton, EuiSuperSelect } from '@elastic/eui';
import { setCookie } from '@/websdk/utils/common';
import lang from '@/constant/lang';
import { locale } from '@/constant/locale';

function App() {
  localStorage.lang = lang;
  return (
    <div className="App">
      <p>{lang ? locale[lang].TMPLASCLOUD_BG_SERVICE : locale['zh-CN'].TMPLASCLOUD_BG_SERVICE}</p>
      <div style={{ marginTop: 10, width: 128, marginBottom: 10 }}>
        <EuiSuperSelect
          options={[
            {
              value: 'zh-CN',
              inputDisplay: '中文简体',
            },
            {
              value: 'zh-TW',
              inputDisplay: '中文繁体',
            },
            {
              value: 'en-US',
              inputDisplay: 'English',
            },
          ]}
          valueOfSelected={lang}
          onChange={lang => {
            localStorage.lang = lang;
            setCookie('lang', lang, '/');
            window.location.reload();
          }}
        />
      </div>
      <EuiButton
        onClick={() => {
          const ports = {
            OAUTH_HTTP_PORT: '8967',
            OAUTH_HTTPS_PORT: '18967',
          };
          const oauthPort =
            window.location.protocol === 'http:' ? ports.OAUTH_HTTP_PORT : ports.OAUTH_HTTPS_PORT;
          const pattIp =
            /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
          let appUrl = window.location.protocol + '//' + window.location.host;
          let oauthUrl;

          const domain = window.location.host.split(':')[0];
          if (pattIp.test(domain) || domain === 'localhost') {
            //如果用户输入的app地址是ip或者localhost
            oauthUrl = window.location.protocol + '//' + domain;
          } else {
            //如果用户输入的app地址是域名
            let domainSplits = domain.split('.');
            domainSplits[0] = 'oauth';
            oauthUrl = window.location.protocol + '//' + domainSplits.join('.');
          }
          //如果用户输入的app地址携带非“80或443”端口，oauth也带上端口
          if (window.location.port) {
            oauthUrl += ':' + oauthPort;
          }

          window.location = `${oauthUrl}/oauth2/authorize?appid=171wwD3ESpK25V7r7FpIZA
				&response_type=code&redirect_uri=${appUrl}/api/exec&lang=${lang}
				&state=${encodeURIComponent(
          JSON.stringify({
            title: '弘塑云',
            copyright: '©2021 宁波弘讯科技股份有限公司',
            customer: '弘讯',
            showAuth: false,
          })
        )}`.replace(/ *[\r|\n] */gm, '');
        }}
      >
        {lang ? locale[lang].START_LOGIN : locale['zh-CN'].START_LOGIN}
      </EuiButton>
    </div>
  );
}

export default App;
