import voca from 'voca';

function compare(value1, value2) {
  if (value1 < value2) return -1;
  else if (value1 > value2) return 1;
  else return 0;
}

function evil(fn) {
  var Fn = Function;
  return new Fn('return ' + fn)();
}

export function turnId(text) {
  let id = text.toString(16);
  let prepend = 8 - id.length;
  for (var i = 0; i < prepend; i++) {
    id = '0' + id;
  }
  id = '0x' + id;
  return id;
}

export function isEmptyObject(obj) {
  for (let key in obj) {
    return false;
  }
  return true;
}

export function sortListById(list) {
  var idList = [];
  var listAfterSort = [];
  var i = 0;
  var j = 0;
  for (i = 0; i < list.length; i++) {
    idList[i] = list[i].id;
  }

  idList.sort(compare);

  for (i = 0; i < idList.length; i++) {
    for (j = 0; i < list.length; j++) {
      if (idList[i] === list[j].id) {
        listAfterSort[i] = list[j];
        break;
      }
    }
  }

  return listAfterSort;
}

export function sortListByName(list) {
  var nameList = [];
  var listAfterSort = [];
  var i = 0;
  var j = 0;
  for (i = 0; i < list.length; i++) {
    nameList[i] = list[i].name;
  }

  nameList.sort();

  for (i = 0; i < nameList.length; i++) {
    for (j = 0; i < list.length; j++) {
      if (nameList[i] === list[j].name) {
        listAfterSort[i] = list[j];
        break;
      }
    }
  }

  return listAfterSort;
}

export function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

export function parseArray(arrStr) {
  let tempKey = 'arr23' + new Date().getTime();
  let arrayJsonStr = '{"' + tempKey + '":' + arrStr + '}';
  let arrayJson;
  if (JSON && JSON.parse) {
    arrayJson = JSON.parse(arrayJsonStr);
  } else {
    arrayJson = evil('(' + arrayJsonStr + ')');
  }
  return arrayJson[tempKey];
}

export function resDataAddKey(data) {
  for (let i = 0; i < data.length; i++) {
    data[i].key = data[i].id;
  }
  return data;
}

export function copyJsonArray(array) {
  var newArray = [];
  for (let i = 0; i < array.length; i++) {
    newArray.push(JSON.parse(JSON.stringify(array[i])));
  }
  return newArray;
}

export function copyJson(json) {
  return JSON.parse(JSON.stringify(json));
}

export function transNestjsonToArray(json, nestKey) {
  let data = [];
  objToStrMap(copyJson(json)).forEach(function (fa) {
    let children = [];
    objToStrMap(fa[nestKey]).forEach(function (child) {
      children.push(child);
    });
    fa[nestKey] = children.slice(0);
    data.push(fa);
  });
  return data;
}

export function formatDurationTime(time) {
  if (time === 0) return '0秒';
  let d;
  let h;
  let m;
  let s;
  let duration = '';
  s = Math.floor(time) % 60;
  m = Math.floor(time / 60) % 60;
  h = Math.floor(time / 3600) % 24;
  d = Math.floor(time / (3600 * 24));
  if (d !== 0) duration = d + '天';
  if (h !== 0) duration += h + '小时';
  if (m !== 0) duration += m + '分钟';
  if (s !== 0) duration += s + '秒';
  return duration;
}

export function randGenerator(n, sum) {
  var aryRet = [];
  var fSumTmp = sum;
  var iAcc = 0;
  for (var i = 0; i < n - 1; i++) {
    var iTmp = Math.ceil(Math.random() * (fSumTmp / 2));
    aryRet.push(iTmp);
    fSumTmp -= iTmp;
    iAcc += iTmp;
  }
  aryRet.push(sum - iAcc);
  return aryRet;
}

export function setCookie(NameOfCookie, value, path) {
  document.cookie = NameOfCookie + '=' + escape(value) + (path ? '; path=' + path : '');
}

export function getCookie(NameOfCookie) {
  if (document.cookie.length > 0) {
    let begin = document.cookie.indexOf(NameOfCookie + '=');
    if (begin !== -1) {
      begin += NameOfCookie.length + 1;
      let end = document.cookie.indexOf('; ', begin);
      if (end === -1) end = document.cookie.length;
      return decodeURI(document.cookie.substring(begin, end));
    }
  }
  return null;
}

export function delCookie(NameOfCookie) {
  if (getCookie(NameOfCookie)) {
    document.cookie =
      NameOfCookie + '=' + getCookie(NameOfCookie) + '; expires=Thu, 01-Jan-70 00:00:01 GMT';
  }
}

export function setPrcOfParamVal(param, prc) {
  if (prc === 0 || param.value === '0') return param;
  else {
    if (prc >= param.value.length) {
      let prepend = '0.';
      for (let i = 0; i < prc - param.value.length; i++) {
        prepend += '0';
      }
      param.value = prepend + param.value;
    } else {
      param.value = voca.insert(param.value, '.', param.value.length - prc);
    }
    return param;
  }
}

//处理科学计数
export function convertNumStr(numStr) {
  let decArr = [];
  let value = numStr;
  if (numStr.indexOf('E') !== -1) {
    decArr = numStr.split('E');
  } else if (numStr.indexOf('e') !== -1) {
    decArr = numStr.split('e');
  }
  if (decArr.length !== 0) {
    const symbol = decArr[1].substr(0, 1);
    const exponent = decArr[1].split(symbol)[1];
    if (parseInt(exponent) !== 0) {
      value = decArr[0].split('.').join('');
      if (symbol === '+') {
        const appendLen = parseInt(exponent) - value.length + 1;
        if (appendLen >= 0) {
          for (let i = 0; i < appendLen; i++) {
            value += '0';
          }
        } else {
          value = voca.insert(value, '.', value.length + appendLen);
        }
      } else {
        let prepend = '0.';
        for (var i = 0; i < parseInt(exponent) - 1; i++) {
          prepend += '0';
        }
        value = prepend + value;
      }
    } else {
      value = decArr[0];
    }
  }
  return value;
}
